/* eslint-disable react/no-danger */
import { connect } from 'react-redux';
import { Header } from './common';
import { disclaimerText, updateHtml } from '../utils';

const CaseSummary = (props) => {
  const trakCase = props.caseGroups[props.match.params.caseId];
  const { summary } = trakCase;

  return (
    <div className="case-summary">
      <Header trakCaseSelected={trakCase} />
      <div className="background-gradient">
        <div className="summary-container">
          <div className="scroll-view">
            <div className="summary-content-container">
              <div className="summary-title">Case Summary and Considerations</div>
              <div
                className="body"
                dangerouslySetInnerHTML={{ __html: updateHtml(summary.body) }}
              />
            </div>
          </div>
          <div className="action-buttons-container">
            <div
              className="back-button"
              role="button"
              tabIndex={0}
              onClick={() => {
                props.history.push(`/cases/${props.match.params.caseId}?panel=last`);
              }}
            >
              <div className="nav-button-text">Back</div>
            </div>
            <div
              className="home-button"
              role="button"
              tabIndex={0}
              onClick={() => {
                props.history.push('/');
              }}
            >
              <div className="nav-button-text">Select a different case</div>
            </div>
          </div>
          <div className="footnotes" dangerouslySetInnerHTML={{ __html: summary.footnotes }} />
          <br />
          <div className="disclaimer">{disclaimerText}</div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ data }) => {
  const { caseGroups } = data;
  return { caseGroups };
};

export default connect(mapStateToProps)(CaseSummary);
