import { AiFillQuestionCircle } from 'react-icons/ai';
import { MdTouchApp } from 'react-icons/md';
import { BiTargetLock } from 'react-icons/bi';

import { Layout1, Layout2, Layout3, Layout4, Layout5, Layout6 } from './ContentPanelLayouts';

const ContentPanel = (props) => {
  const { panel } = props;

  const renderTrakCaseContentPanelIcon = (icon) => {
    switch (icon) {
      case 'questioncircle':
        return <AiFillQuestionCircle size={45} />;
      case 'gesture-tap':
        return (
          <MdTouchApp size={45} />
        );
      case 'target-two':
        return (
          <BiTargetLock size={45} />
        );
      default:
        return <img className="" src="https://via.placeholder.com/45x45" alt="" />;
    }
  };

  let layout = null;
  switch (panel.layout) {
    case 1:
      layout = (
        <Layout1 renderTrakCaseContentPanelIcon={renderTrakCaseContentPanelIcon} {...props} />
      );
      break;
    case 2:
      layout = (
        <Layout2 renderTrakCaseContentPanelIcon={renderTrakCaseContentPanelIcon} {...props} />
      );
      break;
    case 3:
      layout = (
        <Layout3 renderTrakCaseContentPanelIcon={renderTrakCaseContentPanelIcon} {...props} />
      );
      break;
    case 4:
      layout = (
        <Layout4 renderTrakCaseContentPanelIcon={renderTrakCaseContentPanelIcon} {...props} />
      );
      break;
    case 5:
      layout = (
        <Layout5 renderTrakCaseContentPanelIcon={renderTrakCaseContentPanelIcon} {...props} />
      );
      break;
    case 6:
      layout = (
        <Layout6 renderTrakCaseContentPanelIcon={renderTrakCaseContentPanelIcon} {...props} />
      );
      break;
    default:
      return '';
  }

  if (!props.isTrakCaseContentPanelClosed) {
    return (
      <div className="content-panel">
        <div
          className="close-button-container"
          role="button"
          tabIndex={0}
          onClick={() => {
            props.setIsTrakCaseContentPanelClosed(!props.isTrakCaseContentPanelClosed);
          }}
        >
          <div className="close-button-text">Close</div>
        </div>
        {layout}
      </div>
    );
  }
  return (
    <div className="content-panel">
      <div
        className="open-button-container"
        role="button"
        tabIndex={0}
        onClick={() => {
          props.setIsTrakCaseContentPanelClosed(!props.isTrakCaseContentPanelClosed);
        }}
      >
        <div className="open-button-text">Open</div>
      </div>
    </div>
  );
};

export default ContentPanel;
