import { connect } from 'react-redux';
import _ from 'lodash';
import CaseCard from './CaseCard';

const CaseListing = (props) => {
  const { caseGroups } = props;

  const renderCases = () => {
    return _.map(_.values(caseGroups), (trakCase) => {
      return <CaseCard key={trakCase.id} trakCase={trakCase} />;
    });
  };

  return <div className="case-listings">{renderCases()}</div>;
};

const mapStatToProps = ({ data }) => {
  const { caseGroups } = data;
  return { caseGroups };
};

export default connect(mapStatToProps)(CaseListing);
