import CaseListing from './CaseListing';
import { disclaimerText } from '../../utils';
import bayer_logo_color from '../../assets/bayer_logo_color.svg';

const HomeScreen = () => {
  const welcomeHTML = `
    <div>Welcome to TRKVIEW—a virtual microscope case-learning tool. This tool is designed to help pathologists assess and characterize TRK IHC staining patterns across a wide range of adult and pediatric tumor types.</div>
    <div>TRK proteins may be the result of <i>NTRK</i> gene fusions, an important class of oncogenic drivers and an actionable genomic alteration. <i>NTRK</i> gene fusions are actionable alterations.<sup>1,2</sup></div>
    <div>Pan-TRK IHC can provide initial screening; however, because pan-TRK IHC detects both wild-type TRK expression and TRK fusion proteins, positive IHC results require molecular confirmation of <i>NTRK</i> gene fusion. Confirmatory RNA-based NGS is preferred; however, DNA-based NGS, FISH, and RT-PCR may be used.<sup>3</sup></div>
    <div>Pan-TRK IHC staining patterns can vary in intensity (weak, moderate, or strong) and localization (nuclear, cytoplasmic, or membranous) depending on the amount of TRK protein, tumor type, and <i>NTRK</i> fusion partner.<sup>4-6</sup></div>
    <div>Please note that while the VENTANA pan-TRK (EPR17341) Assay, ThermoFisher Oncomine Focus Assay, and ArcherDx NGS and TruSight Tumor 170 panels were used for these analyses, there are other assays available.</div>
    `;

  const footnotesHTML = `
    <div>
      <b>${disclaimerText}</b><br />
      FISH, fluorescence in situ hybridization; IHC, immunohistochemistry; NGS, next‐generation sequencing; <i>NTRK</i>, neurotrophic tyrosine receptor kinase; RT‐PCR, reverse transcription‐polymerase chain reaction; TRK, tropomyosin receptor kinase.<br />
      <b>1.</b> Cocco E et al. <i>Nat Rev Clin Oncol</i>. 2018;15(12):731-747. <b>2.</b> Stransky N et al. <i>Nat Commun</i>. 2014;5:4846. <b>3.</b> Penault-Llorca F et al. <i>J Clin Pathol</i>. 2019;72(7):460-467. <b>4.</b> VENTANA pan-TRK (EPR17341) Assay [product information]. Mannheim, Germany: Roche Diagnostics GmbH; 2018. <b>5.</b> Solomon JP et al. <i>Ann Oncol</i>. 2019;30(suppl 8):viii16-viii22. <b>6.</b> Hechtman JF et al. <i>Am J Surg Pathol</i>. 2017;41(11):1547-1551.
    </div>
    <div style="margin-bottom: 0px;">
      PP-VIT-ALL-0478-1 | PP-LAR-US-0962-1 09/2021 | PP-VIT-ES-0221-1  |  MA-LAR-CH-00891-1  |  MA-LAR-IE-0020-1 |  MA-LAR-IT-0072-1  |  PP-VIT-CA-0177-2
    </div>
    `;

  return (
    <div className="home">
      <div className="home-header">
        <div className="home-logo">
          <span className="color-two">TRK</span>VIEW
        </div>
        <div
          className="home-welcome"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: welcomeHTML }}
        />
      </div>
      <div className="case-btn-wrapper">
        <div className="case-btn">SELECT A CASE TO LEARN MORE</div>
      </div>
      <CaseListing />
      <div className="flex-footer">
        <div>
          <img className="bayer-logo" src={bayer_logo_color} alt="" />
        </div>
        <div className="home-footnotes" style={{ display: 'flex', flexDirection: 'column' }}>
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: footnotesHTML }}
          />
          <div>
            <a href="https://www.trkview.com/privacy-statement">
              <b>Privacy Policy</b>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeScreen;
