/* eslint-disable max-len */
import { useState, useEffect } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import BottomNav from './BottomNav';
// import CaseMap from './CaseMap';
import LeafletMap from './LeafletMap';
import ContentPanel from './ContentPanel';
import { Header } from '../common';
import { updateHtml } from '../../utils';

const getUrlParams = (name) => {
  const results = new RegExp(`${name}=([^&]*)`).exec(window.location.href);
  if (results === null) {
    return null;
  }
  return decodeURI(results[1]);
};

const CaseDetail = (props) => {
  // const mapMarkersRef = useRef([]);

  const updatePanelContentBody = (origPanel) => {
    const panelObj = { ...origPanel };

    panelObj.content = updateHtml(panelObj.content);
    panelObj.contentOne = updateHtml(panelObj.contentOne);
    panelObj.contentTwo = updateHtml(panelObj.contentTwo);
    panelObj.contentRow1 = updateHtml(panelObj.contentRow1);
    panelObj.contentRow2 = updateHtml(panelObj.contentRow2);
    panelObj.areaOneContent = updateHtml(panelObj.areaOneContent);
    panelObj.areaTwoContent = updateHtml(panelObj.areaTwoContent);
    panelObj.learnMore = updateHtml(panelObj.learnMore);

    return panelObj;
  };
  const trakCase = props.caseGroups[props.match.params.caseId];

  const [area, setArea] = useState('');
  const [selectedCases, setSelectedCases] = useState([_.first(trakCase.trakCases).file_name]);
  const [caseMaps, setCaseMaps] = useState([_.first(trakCase.trakCases)]);
  const [panelIdx, setPanelIdx] = useState(0);
  const [panel, setPanel] = useState(
    updatePanelContentBody(_.first(trakCase.trakCaseContentPanelPages))
  );

  useEffect(() => {
    const panelParam = getUrlParams('panel');
    if (panelParam) {
      const idx = parseInt(panelParam, 10);
      if (!_.isNaN(idx) && _.isNumber(idx)) {
        // the param is a number
        if (idx <= trakCase.trakCaseContentPanelPages.length - 1) {
          // the panel index is within this case panels
          setPanelIdx(idx);
        } else {
          // if the index number is not within the case panels default to 0
          setPanelIdx(0);
        }
      } else if (panelParam === 'last') {
        // the last panel
        setPanelIdx(trakCase.trakCaseContentPanelPages.length - 1);
      } else {
        // default to the first panel
        setPanelIdx(0);
      }
      // remove the querystring
      props.history.replace(props.history.location.pathname);
    }
  }, []);

  const [isTrakCaseContentPanelClosed, setIsTrakCaseContentPanelClosed] = useState(false);

  useEffect(() => {
    const newPanel = updatePanelContentBody(trakCase.trakCaseContentPanelPages[panelIdx]);

    if (newPanel.forceTrakCase) {
      const caseMap = _.find(trakCase.trakCases, { title: newPanel.forceTrakCase });
      if (caseMap) {
        setCaseMaps([caseMap]);
        setSelectedCases([caseMap.file_name]);
      }
    }
    setPanel(newPanel);
    setArea('');
  }, [panelIdx]);

  const toggleCaseMap = (tCase) => {
    const existing = _.includes(selectedCases, tCase.file_name);
    // debugger;
    let newCaseMaps = [];
    if (existing) {
      newCaseMaps = _.filter(caseMaps, (elem) => elem.file_name !== tCase.file_name);
    } else {
      newCaseMaps = caseMaps.concat([tCase]);
    }
    setCaseMaps(newCaseMaps);
    setSelectedCases(_.map(newCaseMaps, (mapCase) => mapCase.file_name));
  };

  const getMarkers = (caseMap) => {
    if ((panel.forceTrakCase && caseMap.title === panel.forceTrakCase) || caseMap.slug === 'ihc') {
      // console.log('getMarkers', panel, area);
      return _.get(panel, area, []);
    }
    return [];
  };

  // const getBorderColor = (idx) => {
  //   if (idx === 0) {
  //     return 'red';
  //   }
  //   if (idx === 1) {
  //     return 'green';
  //   }
  //   if (idx === 2) {
  //     return 'blue';
  //   }
  // };

  return (
    <div className="case-detail">
      <Header trakCaseSelected={trakCase} />
      <div className="active-maps-container">
        {caseMaps.map((caseMap) => {
          return (
            <div className="map-container" key={caseMap.file_name}>
              {/* <CaseMap
                trakCase={caseMap}
                markers={getMarkers(caseMap)}
                toggleCaseMap={toggleCaseMap}
              /> */}
              <LeafletMap
                trakCase={caseMap}
                markers={getMarkers(caseMap)}
                toggleCaseMap={toggleCaseMap}
                // borderColor={getBorderColor(idx)}
                caseMapLength={caseMaps.length}
              />
            </div>
          );
        })}
      </div>
      <ContentPanel
        setArea={setArea}
        area={area}
        panel={panel}
        isTrakCaseContentPanelClosed={isTrakCaseContentPanelClosed}
        setIsTrakCaseContentPanelClosed={setIsTrakCaseContentPanelClosed}
      />
      <BottomNav
        caseId={props.match.params.caseId}
        selectedCases={selectedCases}
        trakCases={trakCase.trakCases}
        toggleCaseMap={toggleCaseMap}
        panelIdx={panelIdx}
        setPanelIdx={setPanelIdx}
        maxPanelIdx={trakCase.trakCaseContentPanelPages.length - 1}
      />
    </div>
  );
};

const mapStateToProps = ({ data }) => {
  const { caseGroups } = data;
  return { caseGroups };
};

export default connect(mapStateToProps)(CaseDetail);
