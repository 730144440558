import { useState } from 'react';
import AreaButton from './AreaButton';

const Layout6 = (props) => {
  const { panel, setArea, area } = props;
  const { areaOne } = panel;
  const [activeArea, setActiveArea] = useState('');

  return (
    <div className="layout6">
      <div className="layout-container">
        <div className="content-panel-icon">
          {props.renderTrakCaseContentPanelIcon(props.panel.icon)}
        </div>
        <div
          className="layout-body"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: panel[`${area}Content`] || panel.content }}
        />
        <div className="action-container">
          <div
            className="action-description-container"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: panel.action }}
          />
          <div className="action-buttons-container">
            {areaOne && (
              <AreaButton
                areaName={'areaOne'}
                activeArea={activeArea}
                onClick={() => {
                  setArea('areaOne');
                  setActiveArea('areaOne');
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout6;
