import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { FaRegClone } from 'react-icons/fa';

const BottomNav = (props) => {
  const { caseId, trakCases, selectedCases, panelIdx, setPanelIdx, maxPanelIdx } = props;

  const renderCaseCards = () => {
    return trakCases.map((trakCase, idx) => {
      return (
        <div
          key={idx.toString()}
          className={`case-card ${
            _.includes(selectedCases, trakCase.file_name) ? 'case-card-active' : null
          }`}
          onClick={() => props.toggleCaseMap(trakCase)}
          role="button"
          tabIndex={idx}
        >
          <img
            className="case-card-image"
            src={`https://va-ventana-cases.s3.amazonaws.com/thumbs/${trakCase.file_name}.PNG`}
            alt={trakCase.title}
          />
          <div className="case-card-title">{trakCase.title}</div>
        </div>
      );
    });
  };

  return (
    <div className="bottom-nav">
      <div className="info-container">
        <div className="info-header-container">
          <div>
            <FaRegClone size={30} />
          </div>
          <div className="info-icon-text">Case Images</div>
        </div>
        <div className="info-text">Select 1 or 2 images to view and/or compare</div>
      </div>
      <div className="cases-container">{renderCaseCards()}</div>
      <div className="nav-buttons-container">
        <div
          className="nav-button"
          role="button"
          tabIndex={0}
          onClick={() => {
            // eslint-disable-next-line no-unused-expressions
            panelIdx === 0 ? props.history.push('/') : setPanelIdx(_.max([panelIdx - 1, 0]));
          }}
        >
          Back
        </div>

        {panelIdx + 1 <= maxPanelIdx ? (
          <div
            className="nav-button"
            role="button"
            tabIndex={0}
            onClick={() => {
              setPanelIdx(panelIdx + 1);
            }}
          >
            Next
          </div>
        ) : (
          <div
            className="nav-button summary-button"
            role="button"
            tabIndex={0}
            onClick={() => {
              props.history.push(`/cases/${caseId}/summary`);
            }}
          >
            Summary
          </div>
        )}
      </div>
    </div>
  );
};

export default withRouter(BottomNav);
