const Layout2 = (props) => {
  const { panel } = props;
  return (
    <div className="layout2">
      <div className="layout-container">
        <div className="content-panel-icon">
          {props.renderTrakCaseContentPanelIcon(props.panel.icon)}
        </div>
        <div
          className="layout-body-1"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: panel.contentOne }}
        />
        <div
          className="layout-body-2"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: `<div>${panel.contentTwo}</div>` }}
        />
      </div>
    </div>
  );
};

export default Layout2;
