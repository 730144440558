import { useState } from 'react';
import { AiOutlineUpSquare, AiOutlineDownSquare } from 'react-icons/ai';
import AreaButton from './AreaButton';

const Layout5 = (props) => {
  const { panel, setArea } = props;
  const { areaOne } = panel;
  const [isPanelCollapsed, setIsPanelCollapsed] = useState(true);
  const [isPanelSelected, setIsPanelSelected] = useState(false);
  const [activeArea, setActiveArea] = useState('');

  const moreInfoIcon = () => {
    if (isPanelCollapsed) return <AiOutlineDownSquare />;
    return <AiOutlineUpSquare />;
  };

  const renderPanelMoreInfo = () => {
    if (!isPanelCollapsed) {
      return (
        <div
          className="learn-more-content"
          //  eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: panel.learnMore }}
        />
      );
    }
  };

  return (
    <div className="layout5">
      <div className="layout-container">
        <div className="layout-container-row">
          <div className="content-panel-icon">
            {props.renderTrakCaseContentPanelIcon(props.panel.icon)}
          </div>
          <div className="content-container">
            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: !activeArea.length
                  ? `Note the${panel.content}`
                  : `Red pins indicate${panel.content}`
              }}
            />
            <div className="learn-more-container">
              <div
                className="learn-more-label"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: panel.learnMoreLabel }}
              />
              <div
                role="button"
                tabIndex={0}
                className="more-info-icon"
                onClick={() => {
                  setIsPanelCollapsed(!isPanelCollapsed);
                  setIsPanelSelected(true);
                }}
              >
                {moreInfoIcon()}
              </div>
            </div>
            {renderPanelMoreInfo()}
          </div>
        </div>

        <div className="callOut-container">
          <div
            className="callOut-text"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: panel.callOut }}
          />
        </div>
        <div className="layout-container-row">
          <div className="empty" />
          <div
            className="layout-footnotes"
            style={{ display: isPanelCollapsed && isPanelSelected ? 'none' : 'block' }}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: isPanelCollapsed ? 'DISH, dual in situ hybridization' : panel.footnotes }}
          />
        </div>
      </div>
      <div className="action-container">
        <div
          className="action-description-container"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: activeArea.length
              ? '<br /><br />'
              : 'Tap on the <b>predefined area (Area 1)</b> to see break-apart DISH signals. Or tap “<b>Summary</b>” to proceed.'
          }}
        />
        <div className="action-buttons-container">
          {areaOne && (
            <AreaButton
              areaName={'areaOne'}
              activeArea={activeArea}
              onClick={() => {
                setArea('areaOne');
                setActiveArea('areaOne');
              }}
            />
          )}
        </div>
        <div
          className="action-description-text-bottom"
          style={{ display: activeArea.length ? 'block' : 'none' }}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: panel.action }}
        />
      </div>
    </div>
  );
};

export default Layout5;
