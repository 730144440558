const AreaButton = (props) => {
  const { areaName, activeArea, onClick } = props;
  return (
    <div
      role="button"
      tabIndex={0}
      className={activeArea === areaName ? 'action-button-active' : 'action-button'}
      onClick={onClick}
    >
      <div className="action-button-text">{areaName === 'areaOne' ? 'Area 1' : 'Area 2'}</div>
    </div>
  );
};

export default AreaButton;
