const Layout1 = (props) => {
  const { panel } = props;
  return (
    <div className="layout1">
      <div className="layout-container">
        <div className="content-panel-icon">
          {props.renderTrakCaseContentPanelIcon(props.panel.icon)}
        </div>
        <div
          className="layout-body"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: `<div>${panel.content}</div>` }}
        />
      </div>
    </div>
  );
};

export default Layout1;
