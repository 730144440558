import { useState } from 'react';
import AreaButton from './AreaButton';

const Layout3 = (props) => {
  const { panel, setArea, area } = props;
  const { areaOne, areaTwo } = panel;
  const [activeArea, setActiveArea] = useState('');

  const activeActionHtml = areaTwo !== undefined
    ? 'Tap on the <b>predefined areas (1 or 2)</b> to explore additional staining pattern insights.<br />Or tap “<b>Next</b>” to proceed with the case.'
    : 'Tap “<b>Next</b>” to proceed with the case.';

  return (
    <div className="layout3">
      <div className="layout-container">
        <div className="content-panel-icon">
          {props.renderTrakCaseContentPanelIcon(props.panel.icon)}
        </div>
        <div
          className="layout-body"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: panel[`${area}Content`] || panel.content }}
        />
        <div className="action-container">
          <div
            className="action-description-container"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: activeArea !== '' ? activeActionHtml : panel.action }}
          />
          <div className="action-buttons-container">
            {areaOne && (
              <AreaButton
                areaName={'areaOne'}
                activeArea={activeArea}
                onClick={() => {
                  setArea('areaOne');
                  setActiveArea('areaOne');
                }}
              />
            )}
            {areaTwo && (
              <AreaButton
                areaName={'areaTwo'}
                activeArea={activeArea}
                onClick={() => {
                  setArea('areaTwo');
                  setActiveArea('areaTwo');
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout3;
