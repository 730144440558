import { useEffect, useRef } from 'react';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { MapContainer, TileLayer, Marker, useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

const MapMarkers = (props) => {
  const map = useMap();
  const { markers } = props;
  useEffect(() => {
    if (markers.length) {
      map.fitBounds(markers.map((mrkr) => [mrkr.latitude, mrkr.longitude]));
    }
  }, [markers]);

  const createMarker = (marker) => {
    const pinColor = marker.color || 'red';

    let pinColorURL = '';
    if (pinColor === 'red') {
      pinColorURL = 'http://maps.google.com/mapfiles/ms/icons/red-dot.png';
    } else if (pinColor === 'blue') {
      pinColorURL = 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png';
    } else if (pinColor === 'green') {
      pinColorURL = 'http://maps.google.com/mapfiles/ms/icons/green-dot.png';
    }
    return (
      <Marker
        key={`${marker.latitude}_${marker.longitude}`}
        position={[marker.latitude, marker.longitude]}
        icon={L.icon({ iconUrl: pinColorURL, iconSize: [32, 32], iconAnchor: [16, 32] })}
      />
    );
  };

  return markers.map(createMarker);
};

const LeafletMap = (props) => {
  const mapRef = useRef(null);
  const { markers, trakCase, caseMapLength } = props;

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.invalidateSize();
    }
  }, [caseMapLength]);

  const tileUrl = `https://va-ventana-cases.s3.amazonaws.com/extracted_cases/${trakCase.file_name}/{z}/{y}/{x}.jpg`;

  return (
    <>
      <div className="map-header-container">
        <AiOutlineCloseCircle
          color="#ffffff"
          size={22}
          className="close-circle-icon"
          onClick={() => props.toggleCaseMap(trakCase)}
          role="button"
          tabIndex={0}
        />
        <div className="map-title">{trakCase.title}</div>
      </div>
      <MapContainer
        center={[0, 0]}
        zoom={3}
        minZoom={1}
        maxZoom={trakCase.max_zoom}
        scrollWheelZoom={false}
        className="case-map"
        zoomControl={false}
        // style={{ border: `2px solid ${props.borderColor}` }}
        attributionControl={false}
        whenCreated={(map) => {
          mapRef.current = map;
          L.control.zoom({ position: 'bottomright' }).addTo(map);
        }}
      >
        <TileLayer noWrap url={tileUrl} />
        <MapMarkers markers={markers} />
      </MapContainer>
    </>
  );
};

export default LeafletMap;
