export const disclaimerText =
  'Disclaimer: Pan-TRK IHC staining may vary depending on the reagents and conditions used in the assay protocol, and therefore, these images may not be representative of all types of pan-TRK IHC tests.';

export const updateHtml = (body) => {
  // fontSize: 10; lineHeight: 10;
  if (body) {
    let newBody = body
      .replaceAll('<sup1></sup1>', '<sup>1</sup>')
      .replaceAll('<sup2></sup2>', '<sup>2</sup>')
      .replaceAll('<sup3></sup3>', '<sup>3</sup>')
      .replaceAll('<sup4></sup4>', '<sup>4</sup>')
      .replaceAll('<supcomma></supcomma>', '<sup>,</sup>')
      .replaceAll('<supdash></supdash>', '<sup>-</sup>');
    const fontSizeRgx = newBody.match(/(fontSize: ?(\d+))/);
    if (fontSizeRgx) {
      newBody = newBody.replaceAll(fontSizeRgx[1], `font-size: ${fontSizeRgx[2]}px`);
    }
    const lineHeightRgx = newBody.match(/(lineHeight: ?(\d+))/);
    if (lineHeightRgx) {
      newBody = newBody.replaceAll(lineHeightRgx[1], `line-height: ${lineHeightRgx[2]}px`);
    }
    return newBody;
  }
};
