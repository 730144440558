import { BsGrid3X3GapFill } from 'react-icons/bs';
import { withRouter } from 'react-router-dom';

const Header = (props) => {
  const { trakCaseSelected } = props;

  let { name, title } = trakCaseSelected;

  if (trakCaseSelected.id === 8) {
    title += ', High-Grade, Wild-Type IDH';
  } else if (
    trakCaseSelected.id === 26 ||
    trakCaseSelected.id === 27 ||
    trakCaseSelected.id === 28
  ) {
    name += ' Tissue';
  }

  return (
    <div className="header">
      <div className="header-left-container">
        <div className="trk">TRK</div>
        <div className="view">VIEW</div>
        <div className="vertical-divider" />
        <div
          className="header-cases-touchable"
          onClick={() => props.history.push('/')}
          role="button"
          tabIndex={0}
        >
          <BsGrid3X3GapFill className="thumbnails" size={18} />
          <div className="header-cases">CASES</div>
        </div>
      </div>
      <div className="header-center-container">
        {name}
        <span
          className="vertical-divider"
          style={{ display: name === 'Privacy Policy' ? 'none' : 'block' }}
        />
        {title}
      </div>
      <div className="header-right-container" />
    </div>
  );
};

export default withRouter(Header);
