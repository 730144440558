import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

const cases = require('../assets/allcases.json');

const dataSlice = createSlice({
  name: 'data',
  initialState: {
    // loading: true,
    caseGroups: {}
  },
  reducers: {
    setData(state, action) {
      Object.assign(state, action.payload);
    }
  }
});

export const { setData } = dataSlice.actions;

export default dataSlice.reducer;

export const getData = () => {
  return async (dispatch) => {
    dispatch(setData({ loading: true }));

    // const data = await fetch('https://va-ventana-cases.s3.amazonaws.com/allcases.json', {
    //   headers: { 'Cache-Control': 'no-cache' }
    // });

    // const cases = await data.json();

    dispatch(setData({ caseGroups: _.keyBy(cases, 'id') }));
  };
};
