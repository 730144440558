import { withRouter } from 'react-router-dom';

const CaseCard = (props) => {
  const { trakCase } = props;

  return (
    <div
      className="case-card"
      onClick={() => {
        props.history.push(`/cases/${trakCase.id}`);
      }}
      role="presentation"
    >
      <div className="case-card-name">{trakCase.name}</div>
      <div className="case-card-body">
        <div className="case-card-thumbnail">
          <img src={trakCase.thumbnail} alt={trakCase.alt} />
        </div>
        <div className="case-card-text">
          <div className="case-title">{trakCase.title}</div>
          <div
            className="case-fusion"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: trakCase.fusion }}
          />
          <div className="case-staining">{trakCase.staining}</div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(CaseCard);
