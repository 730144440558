const Layout4 = (props) => {
  const { panel } = props;
  return (
    <div className="layout4">
      <div className="layout-container">
        <div className="layout-container-row">
          <div className="content-panel-icon">
            {props.renderTrakCaseContentPanelIcon(props.panel.icon)}
          </div>
          <div
            className="layout-body"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: `<div>${panel.contentRow1}</div>` }}
          />
        </div>
        <div className="layout-container-row">
          <div className="content-panel-icon">
            {props.renderTrakCaseContentPanelIcon(props.panel.icon)}
          </div>
          <div
            className="layout-body"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: `<div>${panel.contentRow2}</div>` }}
          />
        </div>
        <div className="layout-container-row">
          <div className="empty" />
          <div className="layout-footnotes">
            <div
              className="footnotes-text"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: panel.footnotes }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout4;
