import React from 'react';
import _ from 'lodash';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import CircularProgress from '@material-ui/core/CircularProgress';
import Home from './Home';
import CaseDetail from './CaseDetail';
import CaseSummary from './CaseSummary';
import { getData } from '../redux/dataSlice';
import app_store_download from '../assets/app_store_download.png';

const API_KEY = 'AIzaSyCDyQihFxpmQQC2uNlfQUpjaqVCceki6-8';

const App = (props) => {
  const [mapLoaded, setMapLoaded] = React.useState(false);

  React.useEffect(() => {
    const googleMapScript = document.createElement('script');
    googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}`;
    window.document.body.appendChild(googleMapScript);
    googleMapScript.addEventListener('load', () => {
      setMapLoaded(true);
    });
    props.getData();
  }, []);

  if (_.isEmpty(props.caseGroups) || !mapLoaded) {
    return (
      <div className="flex horizontal-center vertical-center" style={{ height: '100%' }}>
        <CircularProgress size={100} />
      </div>
    );
  }

  if (isMobile) {
    return (
      <div className="mobile-redirect">
        <div className="mobile-body">
          <div>
            TRKView is a free, interactive, virtual microscope for pan‐TRK IHC across tumors.
          </div>
          <div>
            TRKView is available for download from the App store on an iPAD, but not on other mobile
            devices.
          </div>
          <a href="" target="_blank" rel="noreferrer">
            <img className="app-store-download" src={app_store_download} alt="" />
          </a>
          <div>
            You can also access TRKView online, by visiting the{' '}
            <a href="http://trkview.com/" target="_blank" rel="noreferrer">
              TRKVIEW.com
            </a>{' '}
            link on your Mac or PC computer.
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="trakView">
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/cases/:caseId" exact component={CaseDetail} />
        <Route path="/cases/:caseId/summary" exact component={CaseSummary} />
      </Switch>
    </div>
  );
};

const mapStatToProps = ({ data }) => {
  const { caseGroups } = data;
  return { caseGroups };
};

const Main = connect(mapStatToProps, { getData })(App);

export default process.env.NODE_ENV === 'development' ? hot(Main) : Main;
